import React, { createContext, useContext, useState, useEffect } from "react";
import Client from "shopify-buy/index.unoptimized.umd";
import { StoreContext } from "../context/Store";

export const ClientContext = createContext();

const client = Client.buildClient({
  storefrontAccessToken: "fb8eb4cfb801edc394d0ea48ac404d78",
  domain: "justinsburgers.myshopify.com",
});

export const ClientContextProvider = ({ children }) => {
  const { dispatch } = useContext(StoreContext);
  let initialStoreState = {
    client,
    adding: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
  };
  
  const [store, updateStore] = useState(initialStoreState);

  useEffect(() => {
    console.log("UPDATING STORE")
    dispatch({ type: "updateStore", payload: store });
  }, [store])

  useEffect(() => {
    if (client) {

      const productsQuery = client.graphQLClient.query((root) => {
        root.addConnection("products", { args: { first: 10 } }, (product) => {
          product.add("title");
          product.add("tags"); // Add fields to be returned
          product.addConnection(
            "variants",
            { args: { first: 50 } },
            (variant) => {
              variant.add("id");
              variant.add("price");
              variant.add("quantityAvailable");
              variant.add("image", (opts) => {
                opts.add("src");
              });
            }
          );
        });
      });

      client.product.fetchAll().then((response) => {
        dispatch({ type: "setProducts", payload: response });
        client.graphQLClient.send(productsQuery).then(({ model, data }) => {
          dispatch({ type: "setVariantsInventory", payload: model.products });
        });
      });
    }
  }, store);

  let isRemoved = false;

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== "undefined";
      const existingCheckoutID = isBrowser
        ? localStorage.getItem("shopify_checkout_id")
        : null;

      const setCheckoutInState = (checkout) => {
        if (isBrowser) {
          localStorage.setItem("shopify_checkout_id", checkout.id);
        }

        updateStore((prevState) => {
          return { ...prevState, checkout };
        });
      };

      const createNewCheckout = () => store.client.checkout.create();
      const fetchCheckout = (id) => store.client.checkout.fetch(id);

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID);
          // Make sure this cart hasn’t already been purchased.
          if (!isRemoved && !checkout.completedAt) {
            setCheckoutInState(checkout);
            return;
          }
        } catch (e) {
          localStorage.setItem("shopify_checkout_id", null);
        }
      }

      const newCheckout = await createNewCheckout();
      if (!isRemoved) {
        setCheckoutInState(newCheckout);
      }
    };

    initializeCheckout();
  }, [store.client.checkout]);

  useEffect(
    () => () => {
      isRemoved = true;
    },
    []
  );

  return (
    <ClientContext.Provider
      value={{
        store,
        addVariantToCart: (variantId, quantity) => {
          if (variantId === "" || !quantity) {
            console.error("Both a size and quantity are required.");
            return;
          }

          updateStore((prevState) => {
            return { ...prevState, adding: true };
          });

          const { checkout, client } = store;

          const checkoutId = checkout.id;
          const lineItemsToUpdate = [
            { variantId, quantity: parseInt(quantity, 10) },
          ];

          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then((checkout) => {
              updateStore((prevState) => {
                return { ...prevState, checkout, adding: false };
              });
            });
        },
        removeLineItem: (client, checkoutID, lineItemID) => {
          return client.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then((res) => {
              updateStore((prevState) => {
                return { ...prevState, checkout: res };
              });
            });
        },
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
          const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) },
          ];

          return client.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then((res) => {
              updateStore((prevState) => {
                return { ...prevState, checkout: res };
              });
            });
        },
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
