const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID
}

let firebaseInstance
export const getFirebase = firebase => {
  if (firebaseInstance) {
    return firebaseInstance
  }

  firebase.initializeApp(config)
  firebaseInstance = firebase

  return firebase
}

let firestoreInstance
export const getFireStore = () => {
  if (firestoreInstance) {
    return firestoreInstance
  }

  const lazyApp = import('firebase/app')
  const lazyDatabase = import('firebase/firestore')

  return Promise.all([lazyApp,lazyDatabase]).then(([firebase]) => {
    firestoreInstance = new Promise((r)=> r(getFirebase(firebase).firestore));
    return firestoreInstance;
  });
}

let databaseInstance
export const getDatabase = () => {
  if (databaseInstance) {
    return databaseInstance
  }

  const lazyApp = import('firebase/app')
  const lazyDatabase = import('firebase/firestore')

  return Promise.all([lazyApp,lazyDatabase]).then(([firebase]) => {
    databaseInstance = new Promise((r)=> r(getFirebase(firebase).firestore()));
    return databaseInstance;
  });
}

let authInstance
export const getAuthInstance = () => {
  if (authInstance) {
    return authInstance
  }

  const lazyApp = import('firebase/app')
  const lazyAuth = import('firebase/auth')

  return Promise.all([lazyApp,lazyAuth]).then(([firebase]) => {
    authInstance = new Promise((r)=> r(getFirebase(firebase).auth()));
    return authInstance;
  });
}
