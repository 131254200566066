import React from 'react'
import { Link } from 'gatsby'
import ReactSVG from 'react-svg'

import logo from '../img/logo.svg'
import instagram from '../img/social/instagram.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter" style={{backgroundColor: 'black'}}>
        <div className="content has-text-centered">
          <ReactSVG
            src={logo}
            svgStyle={{
              height: '100px',
              width: '100px'
            }}
          />
        </div>
        <div className="content has-text-centered has-text-white-ter">
          <div className="container has-text-white-ter">
              <div className="columns">
                <div className="column is-4">
                <section className="menu">
                    <ul className="menu-list">
                      <li><Link to="/" className="navbar-item">Home</Link></li>
                      <li><Link className="navbar-item" to="/contact">Book Us</Link></li>
                    </ul>
                  </section>
                </div>
                <div className="column social">
                  <a title="instagram" href="https://instagram.com/justinsburgers">
                    <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                    />
                  </a>
                </div>
              </div>
            </div>
        </div>
      </footer>
    )
  }
}

export default Footer
