import React, { useContext } from 'react';
import LineItem from './LineItem';
import AddOns from './AddOns';

import { ClientContext } from '../../context/ClientContext'
import { StoreContext } from '../../context/Store'

export function Cart(props){
  const { store, dispatch } = useContext(StoreContext)
  const {
    store: { client },
  } = useContext(ClientContext);
  

  function handleCartClose(){
    dispatch({type: 'closeCart'})
  }

  function updateQuantityInCart(lineItemId, quantity){
    const checkoutId = store.checkout.id
    const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]

    return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(response => {
      dispatch({type: 'setCheckout', payload: response })
    });
  }

  function removeLineItemInCart(lineItemId){
    const checkoutId = store.checkout.id

    return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(response => {
      dispatch({type: 'setCheckout', payload: response })
    });
  }

  function openCheckout() {
    window.location.assign(store.checkout.webUrl)
  }

  function line_items() {
    return store.checkout.lineItems.map((line_item) => {
      return (
        <LineItem
          updateQuantityInCart={updateQuantityInCart}
          removeLineItemInCart={removeLineItemInCart}
          key={line_item.id.toString()}
          line_item={line_item}
        />
      );
    });
  }

  return (
    <div
      className={`Cart ${store.showCart ? 'Cart--open' : ''}`}
      style={{
        color: 'black'
      }}
    >
      <header className="Cart__header">
        <h2 className="is-size-5">Your Cart</h2>
        <button
          onClick={handleCartClose}
          className="Cart__close">
          ×
        </button>
      </header>
      <ul className="Cart__line-items">
        {store.checkout.lineItems &&
          line_items()
        }

      <a onClick={handleCartClose}>Add Another Burger</a>
      </ul>
      <footer className="Cart__footer">
        <div className="Cart-info clearfix">
          <div className="Cart-info__total Cart-info__small">Subtotal</div>
          <div className="Cart-info__pricing">
            <span className="pricing">$ {store.checkout && store.checkout.subtotalPrice}</span>
          </div>
        </div>
        <div className="Cart-info clearfix">
          <div className="Cart-info__total Cart-info__small">Taxes</div>
          <div className="Cart-info__pricing">
            <span className="pricing">$ {store.checkout && store.checkout.totalTax}</span>
          </div>
        </div>
        <div className="Cart-info clearfix">
          <div className="Cart-info__total Cart-info__small">Total</div>
          <div className="Cart-info__pricing">
            <span className="pricing">$ {store.checkout && store.checkout.totalPrice}</span>
          </div>
        </div>
        <button className="Cart__checkout button" onClick={openCheckout}>Checkout</button>
      </footer>
    </div>
  )
}

export default Cart;
