import React, { useReducer } from 'react';

import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Cart from '../components/shopify/Cart'
import { UserContextProvider } from '../context/UserContext'
import { ClientContextProvider } from "../context/ClientContext";
import { AdminAccessContextProvider } from '../context/AdminAccessContext'

import {
  StoreContext,
  initialState,
  reducer
} from '../context/Store'

import "../stylesheets/shopify.css"
import './all.sass'

function TemplateWrapper({ children }) {
  const [store, dispatch] = useReducer(reducer, initialState);

  return (

    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <StoreContext.Provider value={{ store, dispatch }}>
          <AdminAccessContextProvider>
            <UserContextProvider>
              <ClientContextProvider>
                <div>
                  <Helmet>
                    <html lang="en" />
                    <title>{data.site.siteMetadata.title}</title>
                    <meta
                      name="description"
                      content={data.site.siteMetadata.description}
                    />
                    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
                    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                    <link rel="manifest" href="/site.webmanifest"/>
                    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
                    <meta name="msapplication-TileColor" content="#da532c"/>
                    <meta name="theme-color" content="#ffffff"/>
                    <meta name="theme-color" content="#fff" />

                    <meta property="og:type" content="business.business" />
                    <meta property="og:title" content={data.site.siteMetadata.title} />
                    <meta property="og:url" content="/" />
                    <meta property="og:image" content="/img/og-image.jpg" />

                  </Helmet>
                  <Navbar />
                  <div>{children}</div>
                  <Footer />
                  <Cart />
                </div>
              </ClientContextProvider>
            </UserContextProvider>
          </AdminAccessContextProvider>
        </StoreContext.Provider>
      )}
    />
  )
}

export default TemplateWrapper;
