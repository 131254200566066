import React, {Component} from 'react';

class VariantSelector extends Component {
  render() {
    if(this.props.option.values.length <= 1) return null;
    return (
      <>
        <div>
          {this.props.option.values.map((value, index) => {
            return (
              <div className="variant_radio">
                  <input
                    name={this.props.option.name}
                    type="radio"
                    id={value.value}
                    value={value.value}
                    onChange={this.props.handleOptionChange}
                  />
                <label for={value.value}>
                  {`${value.value} - ${value.quantityAvailable} Available`}
                </label>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default VariantSelector;
