import React, { createContext, useState, useEffect } from "react";

import queryString from 'query-string'

export const AdminAccessContext = createContext();

export function AdminAccessContextProvider({ children }) {
  const [adminAccess, setadminAccess] = useState(false);

  useEffect(() => {
    const adminAccessParamValue = queryString.parse(window.location.search).admin;
    const adminAccessLocalValue = window.localStorage.getItem('adminAccess')

    if(adminAccessParamValue) {
      setadminAccess(adminAccessParamValue === 'true')
      window.localStorage.setItem('adminAccess', adminAccessParamValue === 'true');
    } else if (adminAccessLocalValue){
      setadminAccess(adminAccessLocalValue === 'true')
    } else {
      window.localStorage.setItem('adminAccess', false);
      setadminAccess(false)
    }
  })

  return (
    <AdminAccessContext.Provider value={true}>
      {children}
    </AdminAccessContext.Provider>
  );
}
