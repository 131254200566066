import React, { createContext, useState, useEffect } from "react";

import { getAuthInstance } from '../services/firebase'

export const UserContext = createContext();

export function UserContextProvider({ children }) {
  const [user, setUser] = useState();

  useEffect(() => {
    getAuthInstance().then((authInstance)=>{
      authInstance.signInAnonymously();
      authInstance.onAuthStateChanged((user) =>{
        if (user) {
          setUser(user)
        }
      });
    });
  })

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
}
