import React from 'react';

export const StoreContext = React.createContext();

export const initialState = {
  user: null,
  products: [],
  checkout: {},
  showCart: false
};

export const reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case "reset":
      return initialState;
    case "setUser":
      return { count: state.count + 1 };
    case "updateStore":
      return { ...state, checkout: action.payload.checkout };
    case "setProducts":
      return { ...state, products: action.payload };
    case "setVariantsInventory":
      let newProducts = state.products.map((product, index) => {
        let newVariants = product.variants.map((variant, jindex) => {
          return { ...variant, quantityAvailable: action.payload[index].variants[jindex].quantityAvailable };
        })

        let newOptionWithVariants= product.options.map((option) => {
          let newValues = option.values.map((value, jindex) => {
            return {
              ...value,
              quantityAvailable:
                action.payload[index].variants[jindex].quantityAvailable,
              id:
                action.payload[index].variants[jindex].id,
            };
          });

          return { ...option, values: newValues };
        });

        return {
          ...product,
          variants: newVariants,
          options: newOptionWithVariants,
        };
      })
      return { ...state, products: newProducts };
    case "initializeCheckout":
      return { ...state, checkout: action.payload };
    case "setCheckout":
      return { ...state, checkout: action.payload, showCart: true };
    case "toggleCart":
      return { ...state, showCart: !state.showCart };
    case "closeCart":
      return { ...state, showCart: false };
    default:
      return state;
  }
};
