import React, { useContext, useState } from 'react'
import { Link } from 'gatsby'
import { StoreContext } from '../context/Store'
import { AdminAccessContext } from '../context/AdminAccessContext'

function Navbar(props){
  const {store, dispatch} = useContext(StoreContext)
  const adminAccess = useContext(AdminAccessContext)
  const [isNavOpen, setIsNavOpen] = useState(false)

  function toggleNav(){
    setIsNavOpen(!isNavOpen);
  }

  function toggleCart(){
    dispatch({type: 'toggleCart'})
  }

  function cartLinkText(){
    if(!store.checkout.lineItems) return 'Cart'
    const numberOfItems = store.checkout.lineItems.reduce((t, line_item)=>{
      return t + line_item.quantity
    }, 0)
    return `Cart (${numberOfItems})`
  }

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
      style={{
        backgroundColor: "#070707",
      }}
    >
      <div className="container">
        <div className="navbar-brand">
          <Link
            to="/"
            className="navbar-item"
            title="Logo"
            style={{
              color: "green",
              fontSize: "22px",
            }}
          >
            JB
          </Link>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${isNavOpen ? "is-active" : ""}`}
            data-target="navMenu"
            onClick={toggleNav}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${isNavOpen ? "is-active" : ""}`}
          style={{
            backgroundColor: "black",
          }}
        >
          <div className="navbar-start has-text-centered">
          </div>
          <div className="navbar-end has-text-centered">
            {adminAccess && (
              <Link className="navbar-item" to="/order-new">
                Order
              </Link>
            )}
            {adminAccess && (
              <button
                className="navbar-item"
                onClick={toggleCart}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "1rem",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                {cartLinkText()}
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar
